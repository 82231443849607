// src/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ isLoggedIn, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  if (!isLoggedIn) {
    return null; // Return null if not logged in, navbar will not be rendered
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/">AA Builders</Link>
        </div>
        <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
          <Link to="/stock_price" className="navbar-item">Home</Link>
          <Link to="/add_link" className="navbar-item">Add Link</Link>
          <button onClick={onLogout} className="navbar-item">Logout</button>
        </div>
        <div className="navbar-burger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
