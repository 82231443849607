// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import StockPriceView from './StockPrices/StockPriceView';
import StockPriceLinks from './StockPrices/StockPriceLinks';
import Login from './Components/LoginPage';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    // Assume you have a way to determine if login is successful and set isLoggedIn accordingly
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    // Optionally, clear any stored tokens or user data
    // Navigate to login page
    window.location.href = '/'; // Directly navigate to login page after logout
  };

  return (
    <Router>
      <div>
        {isLoggedIn && <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />} {/* Render Navbar only if logged in */}
        <Routes>
          <Route path="/stock_price" element={isLoggedIn ? <StockPriceView /> : <Navigate to="/" />} />
          <Route path="/add_link" element={isLoggedIn ? <StockPriceLinks /> : <Navigate to="/" />} />
          <Route path="/" element={<Login onLogin={handleLogin} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
