import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";

const StockPriceView = () => {
    const [stockPrices, setStockPrices] = useState([]);
    const [filteredStockPrices, setFilteredStockPrices] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [uniqueCompanyNames, setUniqueCompanyNames] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page

    useEffect(() => {
        fetchStockPrices();
    }, []);

    useEffect(() => {
        const filterStockPrices = () => {
            let filteredData = stockPrices.filter(entry => {
                const entryDate = new Date(entry.date);
                const start = startDate ? new Date(startDate) : null;
                const end = endDate ? new Date(endDate) : null;

                if (start && entryDate < start) return false;
                if (end && entryDate > end) return false;
                if (companyName && entry.companyName !== companyName) return false;

                return true;
            });

            setFilteredStockPrices(filteredData);
        };

        filterStockPrices();
    }, [stockPrices, startDate, endDate, companyName]);

    const fetchStockPrices = () => {
        fetch('http://93.127.194.86:5050/aab_webapp/stock/prices')
            .then(response => response.json())
            .then(data => {
                // Sort data by timestamp in descending order (most recent first)
                const sortedData = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setStockPrices(sortedData);
                setFilteredStockPrices(sortedData); // Initially set filtered data to all data

                // Extract unique company names
                const uniqueNames = Array.from(new Set(sortedData.map(entry => entry.companyName)));
                setUniqueCompanyNames(uniqueNames);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredStockPrices.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredStockPrices.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString('en-US'); // Adjust locale as per your preference
    };

    return (
        <div className="mx-auto">
            <Navbar/>
            <h1 className="text-xl font-bold mb-4">Stock Price</h1>
            <div className="flex space-x-4 mb-4">
                <h1 className="text-xl font-bold mt-1">Filter Data:</h1>
                <input
                    type="date"
                    className="border p-2 font-bold"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                />
                <input
                    type="date"
                    className="border p-2 font-bold"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                />
                <select
                    className="border p-2 font-bold"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                >
                    <option value="">All Companies</option>
                    {uniqueCompanyNames.map((name, index) => (
                        <option key={index} value={name}>{name}</option>
                    ))}
                </select>
            </div>
            <table className="table-auto w-full border-collapse border border-black">
                <thead className="border border-black bg-gray-200">
                    <tr>
                        <th className="px-4 py-2 border">S.No</th>
                        <th className="px-4 py-2 border">Company Name</th>
                        <th className="px-4 py-2 border">Date</th>
                        <th className="px-4 py-2 border">Stock Price</th>
                        <th className="px-4 py-2 border">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((entry, index) => (
                        <tr key={index} className="border-b">
                            <td className="px-4 py-2 border">{index + 1}</td>
                            <td className="px-4 py-2 border">{entry.companyName}</td>
                            <td className="px-4 py-2 border">{entry.date}</td>
                            <td className="px-4 py-2 border">{entry.stockPrice}</td>
                            <td className="px-4 py-2 border">{formatTime(entry.timestamp)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
                <button
                    className="border px-4 py-2 mr-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span className="px-4 py-2">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    className="border px-4 py-2 ml-2"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default StockPriceView;
