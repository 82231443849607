import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";

const StockPriceLinks = () => {
  const [link, setLink] = useState("");
  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    try {
      const response = await fetch("http://93.127.194.86:5050/aab_webapp/stock/links/link");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLinks(data);
    } catch (error) {
      console.error("Error fetching links:", error);
    }
  };

  const handleInputChange = (event) => {
    setLink(event.target.value);
  };

  const handleAddLink = async () => {
    if (link.trim()) {
      try {
        const response = await fetch("http://93.127.194.86:5050/aab_webapp/stock/links/add", {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: link,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Link added:", data);
        alert("Link Added Successfully!!!");
        setLink(""); // Clear the input field
        fetchLinks(); // Fetch links again to update the table
      } catch (error) {
        console.error("Error adding link:", error);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <h1 className="font-bold">Add the Stock Web Page Links:</h1>
      <input
        type="text"
        placeholder="Add the links..."
        value={link}
        onChange={handleInputChange}
        className="border p-2 font-medium mt-4 w-3/4"
      />
      <div>
        <button
          onClick={handleAddLink}
          className="bg-black hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5 ml-3"
        >
          Add
        </button>
      </div>
      <h2 className="font-bold mt-10">Links:</h2>
      <table className="table-auto w-full border-collapse border border-black mt-4">
        <thead className="border border-black bg-gray-500">
          <tr>
            <th className="px-4 py-2 border">S.No</th>
            <th className="px-4 py-2 border">Link</th>
          </tr>
        </thead>
        <tbody>
          {links.map((link, index) => (
            <tr key={index} className="border-b">
              <td className="px-4 py-2 border">{index + 1}</td>
              <td className="px-4 py-2 border">
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.url}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockPriceLinks;
